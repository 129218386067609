import io from 'socket.io-client';
import config from '../config/index';

const socket = io(config.url_api, {
    auth: {
        user: "mini_site"
    }
});



export default socket;
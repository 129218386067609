const theme = {
  colors: {
    background: '#f0f2f5',
    background_items: 'rgba(0, 0, 0, 0.5)',
    border_input: '#bbe5ff',
    text: '#666666',
    text_dark: '#222',
    shape: '#fff',
    scrollbar_thumb: '#c0c0c0',
    stars: 'rgba(255, 128, 103, .5)',
    stars_highlight: '#ff8067',
    text_btn_close: '#ffffff',
    background_btn_close: '#d11d1d'
  },
  font: "'Poppins', sans-serif",
  effects: {
    shadow: "2px 0.5px 5px rgba(0, 0, 0, 0.3) !important",
  }
};

export default theme;
import React, { useState, useEffect } from 'react';
import { Container } from './styles';
import { RiStarSLine, RiStarSFill } from 'react-icons/ri';

interface StarsProps {
  stars: number,
  setStars: (prop: number) => void,
};

interface IStar {
  active: boolean
}

const Stars: React.FC<StarsProps> = ({ stars, setStars }) => {
  const [totalStars] = useState(5);
  const [arrayStars, setArrayStars] = useState<IStar[]>([]);

  useEffect(() => {
    verifyStars();
  }, [stars]);

  const verifyStars = () => {
    var array_stars = [] as IStar[];

    if (stars > 5) stars = 5;
    if (stars < 0) stars = 0;

    for (let i = 0; i < stars; i++) {
      array_stars.push({ active: true });
    }

    for (let y = 0; y < 5 - stars; y++) {
      array_stars.push({ active: false });
    }

    setArrayStars(array_stars);
  }

  return (
    <Container>
      {arrayStars.map((star, index) => (
        <li key={`star${index}`} onClick={() => setStars(index + 1)} className={`${star.active && 'in'}`}>
          <RiStarSFill />
        </li>
      ))}
    </Container>
  );
}

export default Stars;
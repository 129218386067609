import styled from 'styled-components';
import hexToRgb from '../../../utils/hexToRgb';

export const Container = styled.div`
  max-width: 80%;
  padding: 10px 20px;
  margin-top: 20px;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  strong {
    font-weight: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text};
  }

  .content {
    margin: 0;
    letter-spacing: 1px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text_dark};

    p { margin: 0;}
  }

 .download-file {
   display: flex;
   align-items: center;

   &.received {
    color: ${({ theme }) => theme.colors.text};

    svg {
      color: ${({ theme }) => theme.colors.text};
    }

    button {
      border: solid 1px ${({ theme }) => theme.colors.text};
    }
   }

   &.sent {
    color: ${({ theme }) => theme.colors.shape};
   }

   span {
     font-size: 14px;
     margin-right: 3px;
   }

   svg {
     margin-right: 3px;
     color: white;
   }

   button {
    height: 20px;
    width: 20px;
    margin-left: 10px;
    display: flex;
    place-content: center;
    align-items: center;
    border-radius: 50%;
    border: solid 1px white;
    background: none;
    cursor: pointer;

    svg {
        font-size: 20px;
        margin-right: 0;
    }
}
 } 

  small {
    font-size: 10px;
    letter-spacing: 1px;
  }
  
  &.received {
    background-color: ${({ theme }) => `rgba(${hexToRgb(theme.colors.primary).r},
      ${hexToRgb(theme.colors.primary).g},
      ${hexToRgb(theme.colors.primary).b}, 0.08)`};
      align-self: flex-start;
      border-radius: 0 15px 15px 15px;
      
      small {
        color: ${({ theme }) => theme.colors.text};
      }
    }
    &.sent {
      background-color: ${({ theme }) => `rgba(${hexToRgb(theme.colors.primary).r},
      ${hexToRgb(theme.colors.primary).g},
      ${hexToRgb(theme.colors.primary).b}, 0.85)`};
      align-self: flex-end;
      border-radius: 15px 0 15px 15px;
      white-space: pre-wrap;
      
      small {
        color: ${({ theme }) => theme.colors.shape};
        opacity: 0.8;
      }
      
    p {
      color: ${({ theme }) => theme.colors.shape};
    }
  }
`;

export const Imagem = styled.img`
  max-width: 220px;
  border-radius: 10px;
  cursor: pointer;
`

export const FullScreen = styled.div`
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 75px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  place-content: center;

  img {
    margin: auto;
    height: auto;
    max-height: 100%;
    width: auto;
    max-width: 100%;
    zoom: 10;
    min-width: 40%;
  }

  .buttons {
    right: 20px;
    top: 20px;
    display: flex;
    text-align: center;
    position: absolute;
    z-index: 1025;

    a {
        font-size: 20px;
        //border: 1px solid white;
        padding: 5px 10px;
        border-radius: 10px;
        background-color: #5cb85c;
        color: white;
        text-decoration: none;
        margin-right: 10px;
        cursor: pointer;
    }

    button {
        background-color: red;
        color: white;
        border: none;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
            font-size: 30px;
        }
    }
  }
`
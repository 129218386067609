import React, { useEffect, useState } from 'react';

import { Container } from './styles';

interface MapaProps {
  src: string,
}

const Mapa: React.FC<MapaProps> = ({ src }) => {
  return (
    <Container>
      <iframe title="Google Maps - Localização" src={src.substring(src.indexOf('"') + 1, src.lastIndexOf(' w') - 1)} ></iframe>
    </Container>
  );
}

export default Mapa;
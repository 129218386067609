import styled from 'styled-components';

export const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 1010;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.bgform};
  width: 30%;
  position: absolute;
  box-shadow: ${({ theme }) => theme.effects.shadow};
  padding: 30px 50px;
  border-radius: 25px;
  z-index: 1;

  @media (max-width: 1440px) {
    width: 40%;
  }

  @media (max-width: 1024px) {
    width: 60%;
    padding: 25px 25px;
  }

  @media (max-width: 768px) {
    width: 70%;
  }

  @media (max-width: 575px) {
    width: 90%;
  }

  @media (max-height: 650px) {
    height: 90vh;
    overflow-y: scroll;
  }

  h2 {
    font-weight: normal;
    font-weight: bold;
    color:  ${({ theme }) => theme.colors.fontform};
  }

  p {
    color:  ${({ theme }) => theme.colors.fontform};
    margin-top: 10px; 
  }

  button.closeModal {
    background-color: transparent;
    border: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 20px;
    right: 20px;
    font-size: 25px;
    color: red;
    cursor: pointer;
  }

  form {
    margin-top: 25px;
    padding: 15px;

    @media (max-width: 1024px) {
      padding: 0;
    }

    select {
      padding-left: 7px;
      margin-bottom: 10px;
      width: 100%;
      height: 40px;
      border: solid 2px #bbe5ff;
      border-radius: 5px;
      font-size: 15px;

      option {
        background-color: white;
      }

      &:focus {
        background-color: #bbe5ff;
      }
    }

    .wrap-buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button { margin-left: 10px; }
    }

    .assunto-warning {
      color: red;
      position: relative;
      top: -5px;
      font-size: 15px;
    }
  }
`;
import React, { useState, useEffect } from 'react';
import { FiMessageSquare } from 'react-icons/fi';
import { RiSubtractFill } from 'react-icons/ri';
import Socket from '../../services/Socket';
import { sessionGet } from '../../session';
import Chat from '../Chat';
import { SocialNetworks } from '../../components/Socials';

import { Container } from './styles';

interface WrapChatProps {
  phone: string,
  logoUrl: string,
  idEmpresa: number,
  setores: any,
  flagProfileBorder: boolean,
  flagWhatsapp: boolean,
  hasFooter: boolean,
  socials: SocialNetworks[]
};

const WrapChat: React.FC<WrapChatProps> = ({idEmpresa, phone, logoUrl, setores, flagProfileBorder, flagWhatsapp, hasFooter, socials }) => {
  const [chatOpened, setChatOpened] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const idCliente = sessionGet('idCliente');
    const NotifySound = require('../../assets/sounds/notify2.mp3');
    const audio = new Audio(NotifySound.default);
    audio.volume = 0.4;

    if (idCliente) {
      Socket.emit('entrarSala', { room: `usuario${idCliente}` });
      Socket.on('notify_sound_chat_atendimento', () => {
        if (chatOpened === false) {
          var num = count;
          setCount(num + 1);
          audio.play();
        }
      });
      return () => {
        Socket.emit('sairSala', { room: `usuario${idCliente}` });
        Socket.off('notify_sound_chat_atendimento');
      }
    }
  }, [count, chatOpened])

  useEffect(() => {
    window.onresize = () => {
      //setChatOpened(false);
      setWidth(window.innerWidth);
    };
  }, [width])

  return (
    <>
      <Container width={width} chatOpened={chatOpened} hasFooter={hasFooter} onClick={() => { setCount(0); setChatOpened(!chatOpened) }}>
        {!chatOpened && <FiMessageSquare />}
        {chatOpened && <RiSubtractFill />}
        {(width > 950) ? !chatOpened && <span>Chat Online</span> : ''}
        {count > 0 ? <span className="notify-icon">{count}</span> : ''}
      </Container>

      {chatOpened && (
        <Chat idEmpresa={idEmpresa} phone={phone} socials={socials} logoUrl={logoUrl} close={() => setChatOpened(false)} setores={setores} flagProfileBorder={flagProfileBorder} flagWhatsapp={flagWhatsapp} />
      )}
    </>
  );
}

export default WrapChat;
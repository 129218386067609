import React, { useState, useEffect, useCallback } from 'react';
import GoogleAnalytics from '../GoogleAnalytics';
import Swal from 'sweetalert2';
import { RiCloseFill } from 'react-icons/ri';
import { MdError } from 'react-icons/md';

import Api from '../../services/Api'
import config from '../../config';

import { AlertFormModal } from '../AlertFormModal';
import Button from '../Form/Button';
import Input from '../Form/Input';
import Select from '../Form/Select';
import Textarea from '../Form/Textarea';
import { SelectOptions } from '../Form/Select';

import { Wrap, Backdrop, Container } from './styles';

export interface FormModalProps {
  closeModal: () => void;
  subjects: SelectOptions[];
  buttonName:string;
}

const FormModal: React.FC<FormModalProps> = ({ closeModal, subjects, buttonName }) => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [option, setOption] = useState(Number);
  const [setor, setSetor] = useState(Number);
  const [mensagem, setMensagem] = useState('');
  const [securityQuestion, setSecurityQuestion] = useState('');

  const [alertName, setAlertName] = useState(false);
  const [alertEmail, setAlertEmail] = useState(false);
  const [alertOption, setAlertOption] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [alertSecurityQuestion, setAlertSecurityQuestion] = useState(false);

  const [msgAlertName, setMsgAlertName] = useState('');
  const [msgAlertEmail, setMsgAlertEmail] = useState('');
  const [msgAlertMessage, setMsgAlertMessage] = useState('');
  const [msgSecurityQuestion, setMsgSecurityQuestion] = useState('');

  const [validatingName, setValidatingName] = useState(false);
  const [validatingEmail, setValidatingEmail] = useState(false);
  const [validatingMessage, setValidatingMessage] = useState(false);

  const [answer, setAnswer] = useState(Number);
  const [placeHolderQuestion, setPlaceHolderQuestion] = useState('');
  const [phoneMask, setPhoneMask] = useState('(##) #####-####');

  useEffect(() => {
    var n1 = Math.floor(Math.random() * 11) + 1;
    var n2 = Math.floor(Math.random() * 11) + 1;
    const sentence = `Quanto é ${n1} + ${n2}? (Pergunta de segurança)`;
    setAnswer(n1 + n2);
    setPlaceHolderQuestion(sentence);
  }, [])

  useEffect(() => {
    if (subjects.length === 1) {
      const sub = Number(subjects[0].value);
      setOption(sub);
    }
  }, [])

  const eventTrack = (category: string, action: string, label: string) => {
    GoogleAnalytics(category, action, label);
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validatingName) {
      setAlertEmail(false);
      setAlertOption(false);
      setAlertMessage(false);
      setAlertSecurityQuestion(false);

      setAlertName(true);
      nome === '' ? setMsgAlertName('Informe o seu nome para prosseguir.') : setMsgAlertName('Insira seu nome e sobrenome. "Exemplo: João Pereira".')

      setTimeout(function () {
        setAlertName(false);
      }, 5000)
      return;
    }

    if (!validatingEmail) {
      setAlertName(false);
      setAlertOption(false);
      setAlertMessage(false);
      setAlertSecurityQuestion(false);

      setAlertEmail(true);
      email === '' ? setMsgAlertEmail('Informe o seu endereço de e-mail para prosseguir.') : setMsgAlertEmail('Insira um formato válido de e-mail. "Exemplo: nome@email.com".')

      setTimeout(function () {
        setAlertEmail(false);
      }, 5000)
      return;
    }

    if (option === 0) {
      setAlertName(false);
      setAlertEmail(false);
      setAlertMessage(false);
      setAlertSecurityQuestion(false);

      setAlertOption(true);

      setTimeout(function () {
        setAlertOption(false);
      }, 5000)
      return;
    }

    if (!validatingMessage) {
      setAlertName(false);
      setAlertEmail(false);
      setAlertOption(false);
      setAlertSecurityQuestion(false);

      setAlertMessage(true);

      mensagem === '' ? setMsgAlertMessage('Escreva uma mensagem antes de enviar.') : mensagem.length < 5 ? setMsgAlertMessage('Escreva uma mensagem com ao menos cinco caracteres.') : setMsgAlertMessage('A mensagem possui caracteres inválidos. Por favor remova-os.')

      setTimeout(function () {
        setAlertMessage(false);
      }, 5000)
      return;
    }

    if (Number(securityQuestion) === answer) {
      let endpoint = "";

      if (window.location.href.includes('merx.app.br') || window.location.href.includes('localhost')) {
        if (window.location.pathname.lastIndexOf("/")) {
          endpoint = window.location.pathname.substring(
            window.location.pathname.indexOf("/") + 1,
            window.location.pathname.lastIndexOf("/")
          );
        } else {
          endpoint = window.location.pathname.replace(new RegExp("/", "g"), '');
        }
      } else {
        endpoint = window.location.hostname;
      }

      const response = (await Api.post('/form', {
        nome,
        email,
        telefone: phone,
        mensagem,
        assunto: option,
        setor: setor === 0 ? null : setor,
        endpoint: endpoint
      })).data;

      if (response.type === 'success') {
        eventTrack(`${buttonName}`, 'Formulário do fale conosco enviado com sucesso!', 'Formulário enviado.')
        Swal.fire(
          'Obrigado!',
          `${response.msg}`,
          'success'
        );

        closeModal();
      } else if (response.type === 'error') {
        setAlertEmail(true);
        setMsgAlertEmail(response.msg);
        setTimeout(function () {
          setAlertEmail(false);
        }, 5000)
      }
    } else if (securityQuestion === '') {
      setAlertName(false);
      setAlertEmail(false);
      setAlertOption(false);
      setAlertMessage(false);

      setAlertSecurityQuestion(true);
      setMsgSecurityQuestion('Responda a pergunta de segurança antes de enviar.');
      setTimeout(function () {
        setAlertSecurityQuestion(false);
      }, 5000);
    } else {
      setSecurityQuestion('')

      setAlertName(false);
      setAlertEmail(false);
      setAlertOption(false);
      setAlertMessage(false);

      setAlertSecurityQuestion(true);
      setMsgSecurityQuestion('Resposta incorreta.')

      setTimeout(function () {
        setAlertSecurityQuestion(false);
      }, 5000);
    }
  };

  // Função que verifica qual o número de telefone (ceular ou fixo) digitado e coloca a respetiva máscara 
  const checkPhoneMask = useCallback(() => {
    const numbers = phone.split('');

    if (numbers[5] === '9') {
      setPhoneMask('(##) #####-####');
    } else {
      setPhoneMask('(##) ####-####');
    }
  }, [phone]);

  // Função que verifica se o nome foi digitado da maneira correta.
  useEffect(() => {
    let validatedName = /^([A-zà-úÀ-Ú]{3,}\s[A-zà-úÀ-Ú]{2,})/;

    setValidatingName(validatedName.test(nome));
  }, [nome]);

  // Função que verifica se o email foi digitado da maneira correta
  useEffect(() => {
    let validatedEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const mail = email.replace(' ', '');

    setEmail(mail);

    setValidatingEmail(validatedEmail.test(mail));
  }, [email]);

  // Função que verifica se a mensagem está no padrão correto
  useEffect(() => {
    if (mensagem.length < 5) {
      setValidatingMessage(false);
    } else {
      let validatedMessage = /^([A-zà-úÀ-Ú0-9!@#$%?|\/^&*)(}{+-=._`´~ªº°"])/;

      setValidatingMessage(validatedMessage.test(mensagem));
    }
  }, [mensagem]);

  useEffect(() => {
    if (alertName || alertEmail || alertOption || alertMessage) {
      setSecurityQuestion('');
    }
  }, [alertName, alertEmail, alertOption, alertMessage]);

  useEffect(() => {
    if (phone !== '' && phone !== '(__) _____-____') {
      checkPhoneMask();
    }
  }, [phone, checkPhoneMask]);

  return (
    <Wrap>
      <Backdrop onClick={closeModal} />
      <Container>
        <button className="closeModal" onClick={closeModal}><RiCloseFill /></button>
        <h2>{buttonName}</h2>
        <p>Deixe sua mensagem. Em breve, entraremos em contato.</p>
        <form onSubmit={handleSubmit}>
          <Input type="text" placeholder="Nome completo" value={nome} onChange={e => nome.length <= 100 && setNome(e.target.value.substr(0, 99))} />
          {(alertName) && <AlertFormModal type="error" content={msgAlertName} />}

          <Input type="text" placeholder="E-mail" value={email} onChange={e => email.length <= 100 && setEmail(e.target.value.substr(0, 99))} />
          {(alertEmail) && <AlertFormModal type="error" content={msgAlertEmail} />}

          <Input type="text" placeholder="Telefone" value={phone} onChange={e => { setPhone(e.target.value) }} hasMask={true} formatMask={phoneMask} mask="_" />

          <select className="select" value={option} onChange={e => {setOption(Number(e.target.value)); setSetor(Number(e.target.selectedOptions[0].getAttribute('data-setor'))) }} >

            {subjects.length > 1 && <option value={0}>Selecione o assunto</option>}
            {subjects.map(assunto => (
              <option value={assunto.value} data-setor={assunto.setor}>{assunto.label}</option>
            ))}
          </select>
          {(alertOption) && <AlertFormModal type="error" content="Selecione um assunto para continuar." />}

          <Textarea placeholder="Sua mensagem aqui..." rows={5} value={mensagem} onChange={(e: any) => setMensagem(e.target.value)} />
          {(alertMessage) && <AlertFormModal type="error" content={msgAlertMessage} />}

          <Input type="text" placeholder={placeHolderQuestion} value={securityQuestion} onChange={e => setSecurityQuestion(e.target.value)} hasMask={true} formatMask='##' />
          {(alertSecurityQuestion) && <AlertFormModal type="error" content={msgSecurityQuestion} />}

          <div className="wrap-buttons">
            <Button label="Enviar" type="submit" local="form" />
          </div>
        </form>
      </Container>
    </Wrap>
  );
}

export default FormModal;
import styled from 'styled-components';

interface Props {
  typeAlert: String
}

export const Container = styled.div<Props>`
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: ${props => props.typeAlert === 'error' && '#ffdede'};
  border-radius: 5px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  color: red;
  font-size: 12px;
  margin-bottom: 10px;

  svg {
    height: 20px;
    margin-right: 10px;
    font-size: 30px;
  }
`;
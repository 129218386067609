import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  padding: 20px 0px;
  text-align: center;
`

export const Historico = styled.div`
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 10px;
  padding: 15px ;
    p {
      font-size: 15px;
      margin-bottom: 15px;
      margin-top: 0px;
    }

    .options {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      .input-radio {
        margin: 0;
        margin-right: 10px;
        width: unset;
        height: 15px;
      }

      label {
        margin-right: 10px;
      }
    }
`;

import styled, { css } from 'styled-components';
import hexToRgb from '../utils/hexToRgb';

interface ContainerProps {
  backgroundUrl?: string
}

interface FilterProps {
  flagBackgroundFilter: boolean
  hasBg: boolean,
  heightFooter: number
}

interface SocialsAndFormProps {
  flagSocials?: boolean
  flagForm?: boolean
}

interface FooterProps {
  hasFooter: boolean
}

export const Container = styled.div<ContainerProps>`
  background-size: cover;
  background-position: center;
  ${({ backgroundUrl }) => backgroundUrl !== '' && css`
    background: url(${backgroundUrl});
    background-size: cover;
    background-position: center;
  `};
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  min-height: 100vh;

  .whatsapp-corner {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;

    @media (max-width: 575px) {
      font-size: 13px;
      margin-right: 0px;
      margin-bottom: 10px;
    }

    svg {
      font-size: 20px;
    }
  }

  .endereco-corner {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;

    @media (max-width: 575px) {
      margin: 10px 0px;
      font-size: 13px;
    }
  }
`;

export const Filter = styled.div<FilterProps>`
 ${({ hasBg, flagBackgroundFilter, theme }) => hasBg === true ? flagBackgroundFilter && `
  background-image: radial-gradient(circle, rgba(
    ${hexToRgb(theme.colors.quaternary).r}, 
    ${hexToRgb(theme.colors.quaternary).g}, 
    ${hexToRgb(theme.colors.quaternary).b}, 0.9), rgba(0, 0, 0, 0.9));
  ` : ''};
  ${({ heightFooter }) => css`
    height: calc(100vh - ${heightFooter + 'px'});
  `};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0;
  
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.tertiary}; 
  }

  .scroller {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;

    @media (max-width: 1600px) {
      width: 50%;
    }

    @media (max-width: 1024px) {
      /* width: 60%; */
      width: 70%;
    }

    @media (max-width: 768px) {
      width: 70%;
    }

    @media (max-width: 575px) {
      width: 90%;
    }
  }

  .content-links-map {
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: start;
    height: fit-content;
    width: 100%;

  }


`;

export const Footer = styled.div<FooterProps>`
  width: 100%;
  height: 50px;
  display: flex;
  bottom: 0;
  padding: 10px 180px;
  align-items: center;
  place-content: center;
  background: ${({ hasFooter, theme }) => hasFooter ? theme.colors.tertiary : ''};

  @media (min-width: 951px) and (max-width: 1024px) {
    padding: 10px 135px;
  }

  @media (max-width: 950px) {
    padding: 10px 50px;
  }

  @media (max-width: 767px) {
    height: auto;
  }
`;

export const SocialsAndForm = styled.div<SocialsAndFormProps>`
  ${({ flagSocials, flagForm }) => !flagSocials || !flagForm ? css`
    justify-content: center;
  ` : css`
    justify-content: space-between;
  `}
  display: flex;
  align-items: center;
  width: 100%;
  place-content: center;

  .tel-address {
    display: flex;
    align-items: center;

    @media(max-width: 575px) {
      text-align: center;
      flex-direction: column;
    }
  }

  .social-form {
    display: flex;
    place-content: space-between;

    @media (max-width: 700px) {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 575px) {
    width: 90%;
    flex-direction: column;
  }
`;

export const CtaForm = styled.div`
  height: 45px;
  font-size: 18px;
  display: flex;
  padding: 0 25px;
  border-radius: 25px;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.form};

  @media (max-width: 575px) {
    margin-top: 5px;
    height: 30px;
    font-size: 12px;
    padding: 0 10px;
  }

  svg { 
    //color: ${({ theme }) => theme.colors.secondary}; 
    color: ${({ theme }) => theme.colors.btnfont};
    font-size: 25px; margin-right: 5px; 
  }

  span {
    color: ${({ theme }) => theme.colors.btnfont};
    //color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const Loading = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @keyframes rotation {
    from { transform: rotate(0deg); }
    to { transform: rotate(359deg); }
  }

  svg {
    font-size: 30px;
    margin-bottom: 10px;
    animation: rotation 2s infinite linear;
  }

  h2 {
    font-weight: normal;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.text_dark};
  }
`;
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  padding: 20px 0px;
  text-align: center;
`

export const Error = styled.div`
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #ffdede;
  border-radius: 5px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  color: red;
  font-size: 12px;
  margin-bottom: 10px;

  svg {
    height: 20px;
    margin-right: 10px;
    font-size: 30px;
  }
`;

export const SelectHTML = styled.select`
  padding-left: 7px;
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
  border: solid 2px #bbe5ff;
  border-radius: 5px;
  font-size: 15px;

  option {
    background-color: white;
  }

  &:focus {
    background-color: #bbe5ff;
  }
`

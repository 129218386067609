import styled from 'styled-components';

export const Container = styled.input`
  border: 2px solid ${({ theme }) => theme.colors.border_input} !important;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 200;

  /* Remove as setinhas do input number nos navegadores Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Remove as setinhas do input number no navegador Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
`;

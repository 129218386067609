import React, { useEffect, useState } from 'react';
import { Container, Imagem, FullScreen } from './styles';
import { RiFile2Fill, RiArrowDownLine, RiCloseFill } from 'react-icons/ri';

import getFiles from '../../../utils/getFiles';

interface MessageProps {
  message: string,
  autor?: string,
  data: string,
  type: string,
  msg: any
}

const Message: React.FC<MessageProps> = ({ message, autor, data, type, msg }) => {

  const [name, setName] = useState('');
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    if (msg.name_file !== '' && msg.name_file !== null && msg.name_file !== undefined) {
      if (msg.name_file.length > 20) {
        var limit = msg.name_file.substring(0, 20);
        setName(limit + '...');
      } else {
        setName(msg.name_file);
      }
    }
  }, [msg])

  const download = () => {
    var url = getFiles(msg.path);
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
      a.target = "_blank";
      a.download = msg.name_file; // Set the file name.
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      //delete a;
    };
    xhr.open('GET', url);
    xhr.send();
  }

  return (
    <Container className={type}>
      {/* <strong>{autor}</strong> */}
      {msg.path !== '' && msg.path !== undefined && msg.path !== null ?
        (msg.type_file === 'file' ? <div className={`download-file ${type}`}>
          <RiFile2Fill />
          <span>{name}</span>
          <button type="button" onClick={() => { download() }}><RiArrowDownLine /></button>
        </div>
          : <Imagem onClick={() => { setFullScreen(true) }} src={getFiles(msg.path)}></Imagem>)
        : ''}
      <div className="content" dangerouslySetInnerHTML={{ __html: message }}></div>
      <small>{data}</small>
      {fullScreen && (
        <FullScreen>
          <img src={getFiles(msg.path)}></img>
          <div className="buttons">
            <a href={getFiles(msg.path)} target="_blank" className="original">Ver original</a>
            <button onClick={() => { setFullScreen(false) }} className="close"><RiCloseFill /></button>
          </div>
        </FullScreen>
      )}
    </Container>
  );
}

export default Message;
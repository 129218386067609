import { setSeconds } from 'date-fns/esm';
import React, { useState } from 'react';
import { useEffect } from 'react';

import { Historico, Container } from './styles';

import Config from '../../../config';

import Api from '../../../services/Api';

import { clearSession, sessionGet } from '../../../session';

import Button from '../../Form/Button';
import Stars from '../../Form/Stars';
import Textarea from '../../Form/Textarea';

import Logo from '../../Logo';

interface AvaliacaoProps {
  setStep: (step: 'cadastro' | 'fila' | 'conversa' | 'avaliacao') => void;
  logoUrl: string
}

const Avaliacao: React.FC<AvaliacaoProps> = ({ setStep, logoUrl }) => {
  const [stars, setStars] = useState(1);
  const [text, setText] = useState();
  const [status, setStatus] = useState(true);
  const [flagHistorico, setFlagHistorico] = useState(false);
  const [sendHistory, setSendHistory] = useState(0);

  const getPage = async () => {
    let endpoint = "";

    if (window.location.href.includes('merx.app.br') || window.location.href.includes('localhost')) {
      if (window.location.pathname.lastIndexOf("/")) {
        endpoint = window.location.pathname.substring(
          window.location.pathname.indexOf("/") + 1,
          window.location.pathname.lastIndexOf("/")
        );
      } else {
        endpoint = window.location.pathname.replace(new RegExp("/", "g"), '');
      }
    } else {
      endpoint = window.location.hostname;
    }

    let response = (await Api.get(`/page-endpoint/${endpoint}`)).data;
    //const response = ((await Api.get(`/page/${Config.token_page}`)).data).page;
    setFlagHistorico(response.page.flagHistorico);
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const id = sessionGet('idAtendimento');
    const history = Number(sendHistory);
    const response = (await Api.put(`/chat-atendimento/${id}`, { comentario: text, avaliacao: stars, flagHistorico, history })).data;
    clearSession();
    setStatus(false);
  }

  useEffect(() => {
    verificarAtendimento();
    getPage();
  }, [])

  const verificarAtendimento = async () => {
    const id = sessionGet('idAtendimento');

    const response = (await Api.get(`/chat-atendimento/${id}`)).data;

    const dif = new Date().getTime() - new Date(response.data_final).getTime();

    if (dif > 1800000) {
      clearSession();
      setStep('cadastro');
    }
  }

  return (
    <>
      <Container>
        {(logoUrl) && <Logo logoUrl={logoUrl} type='small' />}
        <h3>Atendimento finalizado!</h3>
        {
          status ? (<form onSubmit={e => handleSubmit(e)} className="cadastro">
            <Stars stars={stars} setStars={setStars} />
            <Textarea value={text} onChange={(e: any) => setText(e.target.value)} placeholder="Deixe um comentário sobre o atendimento recebido. " />
            {(flagHistorico) &&
              <Historico>
                <p>Receber histórico de conversa do atendimento por e-mail?</p>
                <div className="options">
                <div>
                  <input className="input-radio" value={1} onChange={(e: any) => setSendHistory(e.target.value)} name="send-history" type="radio" required></input>
                  <label>Sim</label>
                </div>
                <div>
                  <input className="input-radio" value={0} onChange={(e: any) => setSendHistory(e.target.value)} name="send-history" type="radio" required></input>
                  <label>Não</label>
                </div>

                </div>
              </Historico>}
            <Button type="submit" label="Avaliar atendimento" />
          </form>) :
            <>
              {(flagHistorico === true && Number(sendHistory) === 1) && <p className="feedback">Enviamos um e-mail para sua caixa de entrada com o histórico do seu atendimento.</p>}
              <p className="feedback">Obrigado! A sua opinião é muito importante para nós. </p>
            </>
        }
      </Container>
    </>
  );
}

export default Avaliacao;
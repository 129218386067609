import styled from 'styled-components';
import ScrollToBottom from 'react-scroll-to-bottom';

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  height: 75px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px 7px 0 0;
  box-shadow: ${({ theme }) => theme.effects.shadow};

  .info-user {
    display: flex;
    align-items: center;
    
    img {
      margin-right: 10px;
    }
    
    .info-chat {
      display: flex;
      flex-direction: column;
        
      span {
        color: ${({ theme }) => theme.colors.shape};
        letter-spacing: 1px;
      }
      
      small {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }

  .btn-encerrar-atendimento {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.background_btn_close};
    background-color: ${({ theme }) => theme.colors.text_btn_close};
    border: 0;
    border-radius: 50px;
    padding: 7px 20px;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.text_btn_close};
      background-color: ${({ theme }) => theme.colors.background_btn_close};
    }
  }
`;

export const Body = styled(ScrollToBottom)`
  height: calc(100% - 75px - 55px);
  
  .scroll-button {
    display: none;
  }
      
  .scroll-view {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 10px 10px;
    width: 100%;

    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.shape};
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.scrollbar_thumb};
      border-radius: 2px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.text};
    }
  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 10px;

  input {
    width: 90%;
    height: 40px;
    margin: 0;
  }

  button {
    width: 30px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 0;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  width: 100%;
  height: 100vh;
`;


export const Button = styled.div`
  button {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.text_btn_close};
    background-color: ${({ theme }) => theme.colors.background_btn_close};
    border: 0;
    border-radius: 50px;
    padding: 5px 15px;
    cursor: pointer;
  }
`;

export const Modal = styled.div`
  width: 300px;
  height: 160px;
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  position: absolute;
  top: calc(50% - 80px);
  left: calc(50% - 150px);
  z-index: 1050;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .modal-head {
    h5 {
      font-size: 18px;
      text-align: center;
    }
  }

  hr {
    width: 96%;
    height: 1px;
    color: #a8a8a8;
    margin: 0 auto;
  }

  .modal-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    place-content: center;

    .btn-modal-close {
      width: 90px;
      height: 40px;
      font-size: 18px;
      color: white;
      border: none;
      border-radius: 15px;
      place-content: center;
      cursor: pointer;
      display: flex;
      align-items: center;

      &.yes {
        background-color: rgb(92, 184, 92);
        margin-right: 30px;
      }

      &.no {
        background-color: #d11d1d;
      }
    }
  }
`;

export const Whatsapp = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;

  small {
    margin-top: 10px;
  }

  a {
    width: 120px;
    height: 40px;
    margin-top: 20px;
    border: none;
    display: flex;
    color: white;
    font-size: 14px;
    border-radius: 10px;
    align-items: center;
    place-content: center;
    background: #168a51;
    text-decoration: none;

    svg {
      margin-right: 3px;
      font-size: 18px;
    }
  }
`
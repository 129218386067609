import React from 'react';
import { Container } from './styles';
import { NumberFormatValues, PatternFormat } from 'react-number-format';

export interface InputProps {
  type: "text" | "tel" | "password";
  name?: string;
  placeholder: string;
  value?: string;
  setValue?: (value: any) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasMask?: boolean;
  formatMask?: string;
  mask?: string;
  required?: boolean
};

const Input: React.FC<InputProps> = ({ hasMask, formatMask = '', mask, value, setValue, ...rest }) => {
  const handleChangeMask = (values: NumberFormatValues) => {
    if (setValue) setValue(values.formattedValue);
  };

  return (
    <>
      {hasMask && (
        <PatternFormat
          format={formatMask}
          mask={mask}
          customInput={Container}
          value={value}
          onValueChange={handleChangeMask}
          {...rest}
        />
      )}

      {!hasMask && <Container {...rest} />}
    </>
  );
}

export default Input;
import styled, { css } from 'styled-components';
interface ButtonProps {
  type?: string,
  local?: string
};

export const Container = styled.button<ButtonProps>`
  background-color: ${({ theme, type }) => type === 'submit' ? theme.colors.btnform : theme.colors.background};
  background-color: ${({ theme, local }) => local === 'form' ? theme.colors.btnform : theme.colors.primary};
  color: ${({ theme, type }) => type === 'submit' ? theme.colors.btnfont : theme.colors.text_dark};
  border: 0;
  ${({ type, theme }) => type === 'button' && css`border: 1px solid ${theme.colors.text}`};
  border-radius: 20px;
  box-shadow: ${({ theme }) => theme.effects.shadow};
  padding: 0 40px;
  height: 40px;
  margin-bottom: 20px;
  cursor: pointer;
`;

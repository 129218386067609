import React, { useEffect, useState } from 'react';

const Loading: React.FC = () => {
  const [loader, setLoader] = useState('');
  var auxLoader = '';

  const renderLoader = () => {
    const totalChar = auxLoader.length;

    if (totalChar < 3) { auxLoader += '.' }
    else { auxLoader = '' }

    setLoader(auxLoader);
  };

  useEffect(() => {
    const timer = window.setInterval(renderLoader, 500);

    return () => {
      clearInterval(timer);
    }
  }, []);

  return <span>{loader}</span>;
}

export default Loading;
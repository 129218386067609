import React, { useState, useEffect } from 'react';
import Loading from '../Loading';
import Logo from '../../Logo';
import { AtendimentoProps } from '..';
import { SocialNetworks } from '../../../components/Socials';

import { RiWhatsappLine } from "react-icons/ri";

import { Header, Button, Body, Form, Backdrop, Modal, Whatsapp } from './styles';

import Api from '../../../services/Api';

import { sessionGet, clearSession } from '../../../session';
interface FilaProps {
  atendimento: AtendimentoProps,
  logoUrl: string,
  offline: boolean,
  phone: string,
  flagWhatsapp: boolean,
  setStep: (step: 'cadastro' | 'fila' | 'conversa' | 'avaliacao') => void,
  socials: SocialNetworks[]
}

const Fila: React.FC<FilaProps> = ({ setStep, atendimento, logoUrl, offline, phone, flagWhatsapp, socials }) => {

  const [confirmModal, setConfirmModal] = useState(false);
  const [hasWhatsapp, setHasWhatsapp] = useState(false);
  const [whatsapp, setWhatsapp] = useState('');

  const [showWpp, setShowWpp] = useState(false);

  useEffect(() => {
    const whatsappSocial = socials.filter((social: SocialNetworks) => social.type === 'whatsapp');
    let hasWpp = false;
    if (whatsappSocial.length > 0) {
      setHasWhatsapp(true);
      hasWpp = true;
      setWhatsapp(whatsappSocial[0].link);
    }
    setTimeout(() => {
      if (hasWpp) {
        setShowWpp(true);
      }
    }, 30000)
  }, [socials])

  const handleEndService = async () => {
    await Api.get(`/chat-atendimento/encerrar-atendimento/${sessionGet('idAtendimento')}`)
    clearSession();
    setStep('cadastro');
  };

  return (
    <>
      {(logoUrl) && <Logo logoUrl={logoUrl} type="small" />}
      <h3>
        Aguarde um momento!<br />
        <small>Em breve, vamos te atender</small>
        <Loading />
      </h3>
      {showWpp && (
        <Whatsapp>
          <small>Você pode nos chamar pelo Whatsapp:</small>
          <a href={whatsapp} target="_blank">
            <RiWhatsappLine />
            Whatsapp
          </a>
        </Whatsapp>
      )}
      <Button>
        <button className="btn-encerrar-atendimento" onClick={() => setConfirmModal(true)}>Encerrar</button>
      </Button>
      {confirmModal && (
        <>
          <Backdrop onClick={() => setConfirmModal(false)} />
          <Modal>
            <div className="modal-head">
              <h5>Deseja realmente encerrar o atendimento?</h5>
            </div>
            <hr />
            <div className="modal-buttons">
              <button className="btn-modal-close yes" onClick={() => { handleEndService(); setConfirmModal(false) }}>Sim</button>
              <button className="btn-modal-close no" onClick={() => { setConfirmModal(false) }}>Não</button>
            </div>
          </Modal>
        </>
      )}
    </>
  );
}

export default Fila;
import React from 'react';

import { Container } from './styles';

interface InfosProps {
  title: string,
  description: string,
}

const Infos: React.FC<InfosProps> = ({ title, description }) => {
  return (
    <Container>
      <h1>{title}</h1>
      <p>{description}</p>
    </Container>
  );
}

export default Infos;
import styled from 'styled-components';

interface ImageProps {
  type: string,
  flagProfileBorder?: boolean
}

export const Image = styled.img<ImageProps>`
  ${({ type, flagProfileBorder, theme }) => type === 'small' ? `
    width: 100px;
    height: 100px;
    ${(flagProfileBorder) && `border: 2px solid ${theme.colors.border};`}
    padding: 2px;
    object-fit: cover;
    object-position: center;
    ` : type === 'responsive' ? `
        width: 60px;
        height: 60px;
        ${(flagProfileBorder) && `border: 2px solid ${theme.colors.border};`}
        padding: 2px;
    ` : `
    ${(flagProfileBorder) && `border: 3px solid ${theme.colors.border};`}
    width: 125px;
    height: 125px;
    padding: 5px;
    width: 125px;
    min-height: 125px;
  `}
  object-fit: cover;
  border-radius: 50%;
  
  ${({ flagProfileBorder, theme }) => flagProfileBorder && `
    box-shadow: ${theme.effects.shadow};
  `}

  @media (max-width: 1366px) {
    ${({ type }) => type === 'small' && `
      width: 75px;
      height: 75px;
    `}
  }
`;

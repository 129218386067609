export const sessionSet = (dados: any) => {
    localStorage.setItem('dadosCliente', JSON.stringify(dados));
}

export const sessionGet = (dado: any) => {
    const dados = JSON.parse(localStorage.getItem('dadosCliente') as any);

    return (dados) ? dados[dado] : 'Sessão não gravada!';
}

export const sessionGetAll = () => {
    const dados = JSON.parse(localStorage.getItem('dadosCliente') as any);

    return (dados) ? dados : 'Sessão não gravada!';
}

export const sessionAdd = (title: string, dado: any) => {
    let dados = JSON.parse(localStorage.getItem('dadosCliente') as any);

    dados[title] = dado;

    localStorage.setItem('dadosCliente', JSON.stringify(dados));
}

export const isLogged = () => ((localStorage.getItem('dadosCliente')) ? true : false);

export const isLoggedIMAP = () => {
    const dados = JSON.parse(localStorage.getItem('dadosCliente') as any);

    return (dados.token) ? true : false;
}

export const sessionRemove = (title: string) => {
    let dados = JSON.parse(localStorage.getItem('dadosCliente') as any);

    delete dados[title];

    localStorage.setItem('dadosCliente', JSON.stringify(dados));
}

export const clearSession = async () => {
    localStorage.clear();
};

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  list-style: none;
  padding-right: 5px;
  margin-top: 20px;
  background-color: transparent;

  iframe {
    display: flex;
    width: 100%;
    height: 250px;
    border-radius: 5px;
  }

  &:hover {
    filter: brightness(0.85);
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.background_items};
  }

  @media (max-width: 1024px) {
    width: 60%;
  }

  @media (max-width: 768px) {
    width: 70%;
  }
`;
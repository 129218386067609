import styled, { css } from 'styled-components';
interface ContainerProps {
  step: string
}

export const Container = styled.div<ContainerProps>`
  width: 360px;
  height: 600px;
  background-color: ${({ theme }) => theme.colors.shape};
  box-shadow: ${({ theme }) => theme.effects.shadow};    
  position: fixed;
  bottom: 75px;
  right: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: 1050;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.background_items};
  }

  @media (max-width: 1600px) {
    height: 540px;
  }

  @media (max-width: 1366px) {
    height: 480px;
  }

  @media (max-width: 1280px) {
    height: 430px;
  }

  @media (min-width: 767px) {
    bottom: 125px;
  }

  @media (max-width: 767px) {
    height: 430px;
  }

  @media (max-width: 575px) {
    width: 320px;
    right: calc(50% - 160px);
    bottom: 115px;
  }

  @media (max-width: 350px) {
    width: 290px;
    right: calc(50% - 145px);
  }

  
  ${({ step }) => (step === 'cadastro' || step === 'avaliacao' || step === 'fila') && css`
    padding: 10px 20px 20px;
    justify-content: center;
    align-items: center;

    h3 {
      small {
        color: ${({ theme }) => theme.colors.text};
        font-weight: normal;
        font-size: 12px;
      }
    }
  `}
  
  p {
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .feedback {
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  h3 {
    margin-top: 25px;
    letter-spacing: 1px;
    text-align: center;
  }

  form.cadastro {
    width: 100%;
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      height: 40px;
      border: 0;
      border-radius: 5px;
      margin-bottom: 10px;
    }

    button {
      margin-top: 25px;
    }
  }

  .error-msg {
    font-size: 13px;
    color: red;
  }
`;

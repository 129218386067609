import styled from 'styled-components';

export const Container = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 20px 0;

  li {
    font-size: 30px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.stars}; 

    &.in {
      color: ${({ theme }) => theme.colors.stars_highlight};
    }
  }
`;

import React from 'react';
import { Container } from './styles';

export interface ButtonProps {
  label?: string,
  type?: "submit" | "reset" | "button",
  local?: string,
  click?: () => void;
}

const Button: React.FC<ButtonProps> = ({ label, type, click, local }) => {
  return <Container onClick={click} type={type} local={local}>{label}</Container>;
}

export default Button;
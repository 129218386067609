import React from 'react';
import { Image } from './styles';

interface LogoProps {
  logoUrl: string,
  type?: 'small' | 'responsive',
  flagProfileBorder?: boolean
};

const Logo: React.FC<LogoProps> = ({ logoUrl, type = '', flagProfileBorder }) => {
  return (
    <Image src={logoUrl} type={type} flagProfileBorder={flagProfileBorder} alt='logo' />
  );
}

export default Logo;
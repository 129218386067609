export default (title: string, description: string, keywords: string) => {
  document.title = title + " | Merx";
  var meta = document.getElementsByTagName("meta");
  for (var i = 0; i < meta.length; i++) {
    if (meta[i].name.toLowerCase() == "description") {
      meta[i].content = description;
    }

    if (meta[i].name.toLowerCase() == "keywords") {
      meta[i].content = keywords;
    }
  }
}
import styled, { css } from 'styled-components';

interface ContainerProps {
  width: Number,
  mapOpened: boolean,
  hasFooter: boolean
}

export const Container = styled.div<ContainerProps>`
    color: ${({ theme }) => theme.colors.btnfont};
    background-color: ${({ theme }) => theme.colors.primary};
    height: 40px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    align-items: center;
    box-shadow: ${({ theme }) => theme.effects.shadow};
    cursor: pointer;
    border-radius: 20px;
    transition: all .5s;
    z-index: 1000;
    position: absolute;
    right: 20px;

    ${({ hasFooter }) => hasFooter && css`
        @media (min-width: 768px) {
            bottom: 140px;
        }

        @media (max-width: 575px) {
            right: 25px;
            bottom: 100px;
            position: fixed;
        }
    `};

    ${({ width, mapOpened }) => (width < 950) || mapOpened ? `
        width: 40px;
        height: 40px;
        padding: 0;
    `: ''}

    span {
        margin-left: 10px;
    }

    svg {
        font-size: 20px;
    }

    .notify-icon {
        background: red;
        position: absolute;
        left: 20px;
        top: 18px;
        font-size: 13px;
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        place-content: center;
        border-radius: 50%;
        color: white;

        @media(max-width: 950px) {
            left: 10px;
        }
    }   
`;

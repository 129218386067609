import styled from 'styled-components';

export const Container = styled.textarea`
  border: 2px solid ${({ theme }) => theme.colors.border_input};
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  font-size: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 200;
`;

import React, { useState, useEffect, CSSProperties, useRef } from 'react';
import { FiSend } from 'react-icons/fi';

import Api from '../../../services/Api';

import translateDate from '../../../utils/translateDate';
import useAutosizeTextArea from '../../../utils/useResizeTextarea';

import Compressor from 'compressorjs';
import Swal from 'sweetalert2';

import { sessionGet, sessionGetAll } from '../../../session';

import { AtendimentoProps } from '..';
import Message from '../Message';
import Logo from '../../Logo';
import Input from '../../Form/Input';

import { RiAttachmentLine } from 'react-icons/ri';

import { Header, Body, Form, Backdrop, Modal } from './styles';
import { off } from 'process';

export interface Message {
  id: number,
  message: string,
  autor?: string,
  data: string,
  name_file?: string,
  type_file?: string,
  path?: string,
  type: 'sent' | 'received'
}

export interface IAtendimento {
  atendimento: AtendimentoProps,
  messages: Message[],
  setMessages: (messages: Message[]) => void,
  logoUrl: string,
  flagProfileBorder: boolean,
  setStep: (step: 'cadastro' | 'fila' | 'conversa' | 'avaliacao') => void;
}

const Conversa: React.FC<IAtendimento> = ({ setStep, atendimento, messages, setMessages, logoUrl, flagProfileBorder }) => {
  const [message, setMessage] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [clickedEnter, setClickedEnter] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  
  useAutosizeTextArea(textAreaRef.current, message);

  useEffect(() => {
    setTimeout(function () {
      getMessages();
    }, 500)
  }, [])

  useEffect(() => {
    const dados = sessionGetAll();
    if (dados) {
      if (!dados.idAtendimento) {
        setStep('cadastro');
      }
    } else {
      setStep('cadastro');
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', (e: any) => {
      if (e.keyCode === 13 && !e.shiftKey) {
        setClickedEnter(true)
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', (e: any) => {
      if (e.keyCode === 13 && !e.shiftKey) {
        setClickedEnter(true);
      } else {
        setClickedEnter(false);
      }
    });
  }, []);

  const submitOnEnter = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;

    setMessage(val);

    if (clickedEnter) {
      handleSendMessage(message);
    }
  }

  const getMessages = async () => {
    const response = (await Api.get(`/chat-atendimento/messages/${sessionGet('idAtendimento')}`)).data;
    setMessages(response.map((msg: any) => {
      return {
        id: msg.id,
        message: msg.message,
        autor: msg.from.name,
        data: msg.createdAt,
        type: msg.id_from === sessionGet('idCliente') ? 'sent' : 'received',
        name_file: msg.name_file,
        type_file: msg.type_file,
        path: msg.path_img
      }
    }))
  }

  const handleSendMessage = async (e: any, img = false, fileName = '', fileType = '', path = '') => {
    if (e && e.type === 'submit') {
      e.preventDefault();
    }

    if (message !== '' || img == true) {
      const dados = {
        'message': message,
        'id_from': sessionGet('idCliente'),
        'id_to': sessionGet('idAtendente'),
        name_file: fileName,
        type_file: fileType,
        path_img: path
      }

      const response = (await Api.post(`/chat-atendimento/messages/${sessionGet('idAtendimento')}`, dados)).data;

      const newMessage: Message = {
        id: response.id,
        message,
        autor: 'Usuário',
        data: response.createdAt,
        type: 'sent',
        name_file: response.name_file,
        type_file: response.type_file,
        path: response.path_img
      };

      setMessages([...messages, newMessage]);
      setMessage('');
    }
  }

  const click = () => {
    const input = document.getElementById('input-any-file');
    if (input) {
      input.click();
    }
  }

  const uploadFiles = async (files: any) => {

    let fileName = '';
    let fileType = '';
    var path = '';

    if (files.length > 0) {
      if (files[0].size < 25000000) {
        var type = files[0].type;
        type = type.split('/');
        type = type[0];

        if (type === 'image') {

          const dados = new FormData();
          dados.append('modulo', 'chat');
          fileType = 'image';
          if (files != '') {
            fileName = files[0].name;
            new Compressor(files[0], {
              quality: 0.4,
              async success(result: any) {
                dados.append('file', result, result.name);
                const response = (await Api.post('/upload', dados)).data;
                path = response.path;
                handleSendMessage(null, true, fileName, fileType, path);
              }
            });
          }
        } else {
          if (files !== '') {
            const dados = new FormData();
            dados.append('modulo', 'file-chat');
            fileType = 'file';
            dados.append('file', files[0], files[0].name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
            fileName = files[0].name;
            const response = (await Api.post('/upload', dados)).data;
            path = response.path;
            handleSendMessage(null, true, fileName, fileType, path);
          }
        }
      } else {
        Swal.fire({
          title: 'Erro!',
          text: 'O arquivo ultrapassa o limite máximo de 25mb.',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
      }
    }
  };

  const handleEndService = async () => {
    await Api.get(`/chat-atendimento/encerrar-atendimento/${sessionGet('idAtendimento')}`)
  };

  const readMsg = async () => {
    const mensagens = (await Api.get(`/chat-atendimento/view-messages/${sessionGet('idAtendente')}/${sessionGet('idCliente')}`)).data;
  }

  useEffect(() => {
    readMsg();
  }, [messages])

  return (
    <>
      <Header>
        <div className="info-user">
          {(logoUrl) && <Logo logoUrl={logoUrl} type="responsive" flagProfileBorder={flagProfileBorder} />}
          <div className="info-chat">
            {atendimento.nomeAtendente && (
              <>
                <span>{atendimento.nomeAtendente}</span>
                <small>{atendimento.nomeSetor}</small>
              </>
            )}
          </div>
        </div>
        <button className="btn-encerrar-atendimento" onClick={() => setConfirmModal(true)}>Encerrar</button>
      </Header>
      {confirmModal && (
        <>
          <Backdrop onClick={() => setConfirmModal(false)} />
          <Modal>
            <div className="modal-head">
              <h5>Deseja realmente encerrar o atendimento?</h5>
            </div>
            <hr />
            <div className="modal-buttons">
              <button className="btn-modal-close yes" onClick={() => { handleEndService(); setConfirmModal(false) }}>Sim</button>
              <button className="btn-modal-close no" onClick={() => { setConfirmModal(false) }}>Não</button>
            </div>
          </Modal>
        </>
      )}
      <Body scrollViewClassName="scroll-view" followButtonClassName="scroll-button">
        {messages.map(itemMessage => <Message key={itemMessage.id} message={itemMessage.message} autor={itemMessage.autor} data={translateDate(itemMessage.data)} type={itemMessage.type} msg={itemMessage} />)}
      </Body>
      <Form onSubmit={handleSendMessage}>
        <textarea id='message'  ref={textAreaRef} value={message} onChange={(e) => submitOnEnter(e)} placeholder="Escreva sua mensagem..." />
        <button type="button" onClick={() => { click() }}><RiAttachmentLine /></button>
        <input className="input-anyfile" id="input-any-file" type="file" onChange={e => uploadFiles(e.target.files)} />
        <button type="submit"><FiSend /></button>
      </Form>
    </>
  );
}

export default Conversa;

import React from 'react';
import { Container } from './styles';

export interface TextareaProps {
  name?: string,
  placeholder?: string,
  value?: string,
  onChange?: any,
  rows?: number,
};

const Textarea: React.FC<TextareaProps> = ({ ...rest }) => {
  return <Container {...rest}></Container>;
}

export default Textarea;
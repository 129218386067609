import React from 'react';
import GoogleAnalytics from '../GoogleAnalytics';
import { Container, Item } from './styles';
import { FiFacebook, FiInstagram, FiTwitter, FiYoutube, FiLinkedin } from 'react-icons/fi';
import { RiWhatsappLine } from "react-icons/ri";
import { AiOutlineMail } from "react-icons/ai";

export interface SocialNetworks {
  id: number,
  link: string,
  type: string,
  position: number
}

interface SocialsProps {
  values: any,
}

const Socials: React.FC<SocialsProps> = ({ values }) => {
  const socialIcons = {
    facebook: <FiFacebook />,
    instagram: <FiInstagram />,
    twitter: <FiTwitter />,
    whatsapp: <RiWhatsappLine />,
    youtube: <FiYoutube />,
    linkedin: <FiLinkedin />,
    mail: <AiOutlineMail />
  }

  const socialLabels = {
    facebook: 'facebookIcon',
    instagram: 'instagramIcon',
    twitter: 'twitterIcon',
    whatsapp: 'whatsappIcon',
    youtube: 'youtubeIcon',
    linkedin: 'linkedinIcon',
    mail: 'emailIcon'
  }

  const eventTrack = (category: string, action: string, label: string) => {
    GoogleAnalytics(category, action, label);
  }

  return (
    <Container>
      {values.map((item: any) =>
        <Item>
          <a key={item.id} onClick={eventTrack.bind(this, "Redes sociais", "Acesso a rede social: " + item.type, "Efetuado acesso a rede social através do mini site.")} href={item.type === 'mail' ? `mailto:${item.link}` : item.link} target="_blank" rel="noopener noreferrer" aria-label={(socialLabels as any)[item.type]}>
            {(socialIcons as Record<string, JSX.Element>)[item.type]}
          </a>
        </Item>
      )}
    </Container>
  );
}

export default Socials;
import React, { useState } from 'react';
import { Container } from './styles';
import Cadastro, { StepProps } from './Cadastro';
import Fila from './Fila';
import Conversa from './Conversa';
import Avaliacao from './Avaliacao';
import SocketChat from '../../Socket/Chat';
import { useEffect } from 'react';
import { Message } from './Conversa';
import { isLogged, sessionGetAll, sessionGet, clearSession } from '../../session';
import Api from '../../services/Api';
import { SocialNetworks } from '../../components/Socials';

interface ChatProps {
  idEmpresa: number,

  phone: string,
  close: () => void,
  logoUrl: string,
  setores: any,
  flagProfileBorder: boolean,
  flagWhatsapp: boolean,
  socials: SocialNetworks[]
}

export interface AtendimentoProps {
  idAtendimento: number,
  idCliente: number,
  idEmpresa: number,
  idAtendente?: number,
  nomeAtendente?: string,
  idSetor?: number,
  nomeSetor?: string,
  setorTransferencia?: number
}

const Chat: React.FC<ChatProps> = ({idEmpresa, phone, close, logoUrl, setores, flagProfileBorder, flagWhatsapp, socials }) => {
  const [step, setStep] = useState<'cadastro' | 'fila' | 'conversa' | 'avaliacao'>('cadastro');
  const [atendimento, setAtendimento] = useState<AtendimentoProps>({} as AtendimentoProps);
  const [messages, setMessages] = useState<Message[]>([]);
  const [offline, setOffline] = useState(false);

  useEffect(() => {
    if (isLogged() && idEmpresa === sessionGet('idEmpresa')) {


      checkAtendimento();

      setAtendimento(sessionGetAll);

      const stepCheck = sessionGet('step');

      if (stepCheck === 'avaliacao') {
        setStep('avaliacao')
      } else if (stepCheck === 'fila') {
        setStep('fila');
      } else {
        setStep('conversa');
      }
    }
  }, []);

  const checkAtendimento = async () => {
    const response = (await Api.get(`/chat-atendimento/${sessionGet('idAtendimento')}`)).data;

    if (response) {
      if (response.status === 'finalizado') {
        clearSession();
        setStep('cadastro');
      }
    }

  };

  return (
    <>
      <SocketChat
        phone={phone}
        step={step}
        setStep={setStep}
        idCliente={atendimento.idCliente ? atendimento.idCliente : null}
        setAtendimento={setAtendimento}
        messages={messages}
        setMessages={setMessages}
        setOffline={setOffline} />
      <Container step={step}>
        {step === 'cadastro' && <Cadastro setStep={(item => setStep(item))} setAtendimento={(item => setAtendimento(item))} logoUrl={logoUrl} listSetores={setores} flagProfileBorder={flagProfileBorder} />}
        {step === 'fila' && <Fila socials={socials} setStep={setStep} atendimento={atendimento} logoUrl={logoUrl} offline={offline} phone={phone} flagWhatsapp={flagWhatsapp} />}
        {step === 'conversa' && <Conversa setStep={setStep} atendimento={atendimento} messages={messages} setMessages={setMessages} logoUrl={logoUrl} flagProfileBorder={flagProfileBorder} />}
        {step === 'avaliacao' && <Avaliacao setStep={setStep} logoUrl={logoUrl} />}
      </Container>
    </>
  );
}

export default Chat;
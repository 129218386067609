import styled from 'styled-components';

export const Container = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
`;

export const Item = styled.li`
  font-size: 25px;
  color: ${({ theme }) => theme.colors.shape};
  margin: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 575px) {
    font-size: 20px;
  }

  a {
    height: 25px;
    color: ${({ theme }) => theme.colors.networks};
  }
`;
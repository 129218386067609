import React from 'react';
import { MdError } from 'react-icons/md';

import { Container } from './styles';

interface Props {
  type: String,
  content: String
}

export const AlertFormModal: React.FC<Props> = ({ type, content }) => {
  return (
    <Container typeAlert={type}>
      {type && <MdError />}
      <span>{content}</span>
    </Container>
  );
}
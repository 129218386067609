import styled from 'styled-components';

export const Container = styled.ul`
  width: 100%;
  list-style: none;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.background_items};
  }

  @media (max-width: 1600px) {
    margin-bottom: 15px;
  }

  @media (max-width: 1024px) {
    width: 60%;
  }

  @media (max-width: 768px) {
    width: 70%;
  }

  .lista-links{
  background-color: ${({ theme }) => theme.colors.tertiary};
  border-radius: 5px;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.effects.shadow};
  margin-bottom: 10px;
  transition: filter 0.3s;
  padding: 10px;
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
  }

  &:hover {
    filter: brightness(0.85);
  }

  &.contato {
    margin-top: 20px;
    background-color: ${({ theme }) => theme.colors.form};
    
    .font-contato {
      color: ${({ theme }) => theme.colors.btnfont} !important;
    }

    &:hover {
      filter: brightness(0.85);
    }
  }

  &:visited {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &:last-child {
    margin-bottom: 0;
  }

  .desc-item {
    width: 100%;

    &.icon {
      width: calc(100% - 40px);
    }
    
    span { 
      color: ${({ theme }) => theme.colors.secondary} !important;
      font-size: 16px;

      @media (max-width: 1366px) {
        font-size: .9em;
      }

      @media (max-width: 575px) {
        font-size: .75em;
      }
    }
  }
  }
`;

export const Item = styled.a`
  text-decoration: none;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  text-align: center !important;
  align-items: center;

  @media (max-width: 1600px) {
    padding: 10px;
  }
`;

export const FaleConosco = styled.div`
  background-color: ${({ theme }) => theme.colors.tertiary};
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.effects.shadow};
  margin-bottom: 10px;
  text-decoration: none;
  transition: filter 0.3s;

  &:hover {
    filter: brightness(0.85);
  }

  @media (max-width: 1600px) {
    padding: 10px;
  }

  &.contato {
    margin-top: 20px;
    background-color: ${({ theme }) => theme.colors.form};
    
    .font-contato {
      color: ${({ theme }) => theme.colors.btnfont} !important;
    }

    &:hover {
      filter: brightness(0.85);
    }
  }

  &:visited {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &:last-child {
    margin-bottom: 0;
  }

  .desc-item {
    display: flex;
    flex-direction: column;

    span { 
      color: ${({ theme }) => theme.colors.secondary} !important;
      font-size: 16px;

      @media (max-width: 1366px) {
        font-size: .9em;
      }

      @media (max-width: 575px) {
        font-size: .75em;
      }
    }
  }
`;
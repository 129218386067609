import React, { useEffect, useState } from 'react';
import Socket from '../../services/Socket';
import Api from '../../services/Api';
import { IAtendimento } from '../../components/Chat/Conversa';
import { Message } from '../../components/Chat/Conversa';
import { sessionSet, sessionGet, clearSession, sessionAdd, sessionGetAll } from '../../session';
interface MessageProps {
  mensagem: Message
}

interface FilaSocketProps {
  phone: string,
  idCliente: number | null,
  step: string,
  setStep: (step: 'cadastro' | 'fila' | 'conversa' | 'avaliacao') => void;
  setAtendimento: any;
  messages: Message[],
  setMessages: (messages: Message[]) => void;
  setOffline: any;
}

const Chat: React.FC<FilaSocketProps> = ({ phone, idCliente, step, setStep, setAtendimento, messages, setMessages, setOffline }) => {


  const [counter, setCounter] = useState(0);

  const methodsStatusAtendimento = (scope: 'on' | 'off') => {
    if (idCliente !== null) {
      if (scope === 'on') {
        Socket.emit('entrarSala',{
          room: `usuario${idCliente}`
        });
        Socket.on('atendimento_iniciado', ({ atendimento }: IAtendimento) => {
          sessionSet({ ...atendimento, step: 'conversa' });
          setStep('conversa');
          setAtendimento(atendimento);
          sessionSet(atendimento);
        });
        Socket.on('atendimento_transferido', ({ atendimento }: IAtendimento) => {
          sessionAdd('step', 'fila')
          clearSession();
          sessionSet({
            idAtendimento: atendimento.idAtendimento,
            idCliente: atendimento.idCliente,
            idSetor: atendimento.idSetor,
            step: 'fila',
            type: "success"
          })
          transferirAtendimento(atendimento);
          setStep('fila');
        });
        Socket.on('atendimento_finalizado', () => {
          const hasSession = sessionGetAll();
          if (hasSession) {
            sessionAdd('step', 'avaliacao')
            if (step === 'fila') {
              setStep('cadastro')
            } else {
              setStep('avaliacao');
            }
          }
        });
        Socket.on('nenhum_atendente_online', () => {
          getEmpresa();
        })
      } else if (scope === 'off') {
        Socket.emit('sairSala', { room: `usuario${idCliente}` });
        Socket.off('atendimento_iniciado');
        Socket.off('atendimento_transferido');
        Socket.off('atendimento_finalizado');
        Socket.off('nenhum_atendente_online');
      }
    }
  }

  const getEmpresa = async () => {
    if (counter === 2) {
      await Api.get(`/chat-atendimento/encerrar-atendimento/${sessionGet('idAtendimento')}?offline=true`)
      clearSession();
      setOffline(true);
      setCounter(0);
    }
    setCounter(counter + 1);
  }

  const transferirAtendimento = async (atendimento: any) => {
    let endpoint = "";

    if (window.location.href.includes('merx.app.br') || window.location.href.includes('localhost')) {
      if (window.location.pathname.lastIndexOf("/")) {
        endpoint = window.location.pathname.substring(
          window.location.pathname.indexOf("/") + 1,
          window.location.pathname.lastIndexOf("/")
        );
      } else {
        endpoint = window.location.pathname.replace(new RegExp("/", "g"), '');
      }
    } else {
      endpoint = window.location.hostname;
    }

    const response = (await Api.post('/chat-atendimento', {
      id_setor: atendimento.setorTransferencia,
      endpoint: endpoint,
      id_cliente: atendimento.idCliente
    })).data;
  }

  const methodsMensagens = (scope: 'on' | 'off') => {
    const NotifySound = require('../../assets/sounds/notify2.mp3');
    const audio = new Audio(NotifySound.default);
    audio.volume = 0.4;

    if (scope === 'on') {
      Socket.on('envio_mensagem_chat_atendimento', ({ mensagem }: MessageProps) => {
        setMessages([...messages, mensagem]);
        audio.play();
      });
    } else if (scope === 'off') {
      Socket.off('envio_mensagem_chat_atendimento');
    }
  };

  useEffect(() => {
    methodsStatusAtendimento('on');
    methodsMensagens('on');

    return () => {
      methodsStatusAtendimento('off');
      methodsMensagens('off');
    }
  }, [idCliente, messages, step, counter]);

  return (<></>);
}

export default Chat;
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 25px;

  @media (max-width: 575px) {
    margin-top: 5px;
  }

  h1 {
    color: ${({ theme }) => theme.colors.description};
    margin-bottom: 10px;
    letter-spacing: 1px;
    font-size: 28px;
  }

  p {
    width: 100%;
    color: ${({ theme }) => theme.colors.description};
    text-align: center;
    white-space: pre-wrap;
  }

  @media (max-width: 1600px) {
    margin: 10px 0 15px;
  }

  @media (max-width: 1366px) {
    h1 {
      font-size: 1.5em;
    }
    
    p {
      font-size: .9em;
    }
  }

  @media (max-width: 1024px) {
    width: 60%;
  }

  @media (max-width: 768px) {
    width: 70%;
  }

  @media (max-width: 575px) {
    width: 90%;

    h1 {
      font-size: 1em;
    }
    
    p {
      font-size: .75em;
    }
  }
`;
